import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import Modal from "react-bootstrap/Modal"

import Layout from "../../components/layout"
import OwlCarousel from "../../components/owl-carousel"
import ConfianEnNosotros from "../../components/confian-en-nosotros"

import bgCel from "../../assets/old/img/cotizador/bg-cel.png"
import imgCel from "../../assets/old/img/cotizador/img-cel.png"
import testimoniosH1 from "../../assets/old/img/testimonios/h1.jpg"
import testimoniosH2 from "../../assets/old/img/testimonios/h2.jpg"
import testimoniosM1 from "../../assets/old/img/testimonios/m1.jpg"
import testimoniosM2 from "../../assets/old/img/testimonios/m2.jpg"
import wdsPattern from "../../assets/old/img/page-title/webdelseguro-pattern.png"
import pdp from "../../assets/old/img/footer/pdp.png"
import ssn from "../../assets/old/img/footer/ssn.png"

export default class extends React.Component {
  state = {
    preCotizacionFormData: {},
    showCotizacionForm: false,
  }

  onPreCotizacionFormSubmit = values => {
    this.setState({
      preCotizacionFormData: values,
      showCotizacionForm: true,
    })
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://www.google.com/recaptcha/api.js?render=explicit"
    document.getElementsByTagName("head")[0].appendChild(script)
  }

  onCotizacionModalSubmit = values => {
    this.setState({ showCotizacionForm: false })
    setTimeout(() => {
      navigate("/resultados/", {
        state: { ...values, ...this.state.preCotizacionFormData },
      })
    }, 300)
  }

  render = () => (
    <Layout menuActivo="inicio">
      <Helmet>
        <title>Cotiza tu seguro de tu celular</title>
        <meta
          name="description"
          content="Cotiza con nosotros tu seguro de tu celular y obtén beneficios en tus nuevas pólizas."
        />
        <link rel="preconnect" href="https://webpack.wokan.com.ar" />
      </Helmet>

      <section
        className="bg-w pt-3 pb-5 bghome-hogar"
        style={{ backgroundImage: `url(${bgCel})` }}
      >
        <div className="container text-center pt-2 pb-5 mb-5">
          <div className="row pb-1 mb-3">
            <div className="col-md-12 mb-30">
              <h1 className="display-4 text-uppercase">
                <span className="text-primary">¡</span>Cotizá online el seguro de tu celular<span className="text-primary">!</span> <span role="img" aria-label="Casa">📱</span>
              </h1>
              <h2
                className="h4 block-title text-center mt-1 text-color-444" >
                Sientete seguro ahorrando hasta un 30% <span role="img" aria-label="Corneta">📢</span>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="container pb-3 cotizador-gral-hogar">
        <div className="bg-white4 box-shadow-home px-3 px-sm-5 pt-2 pb-2 text-center text-sm-left">

          <form className="pt-2">

            <div className="row">
              <div className="col-lg-12">

              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label className="text-primary" htmlFor="suma-asegurada">Suma Asegurada</label>
                  <select name="suma-asegurada" className="form-control"  id="suma-asegurada">
                    <option></option>
                    <option value="10">$10.000</option>
                    <option value="20">$20.000</option>
                    <option value="30">$30.000</option>
                    <option value="40">$40.000</option>
                    <option value="50">$50.000</option>
                    <option value="60">$60.000</option>
                    <option value="70">$70.000</option>
                    <option value="80">$80.000</option>
                    <option value="90">$90.000</option>
                    <option value="100">$100.000</option>
                    <option value="110">$110.000</option>
                    <option value="120">$120.000</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label className="text-primary" htmlFor="marca">Marca</label>
                  <select name="marca" className="form-control"  id="marca">
                    <option></option>
                    <option value="01">Samsung</option>
                    <option value="01">Apple</option>
                    <option value="01">Huawei</option>
                    <option value="01">Xiaomi</option>
                    <option value="01">Honor</option>
                    <option value="01">LG</option>
                    <option value="01">Sony</option>
                    <option value="01">BlackBerry</option>
                    <option value="01">OPPO</option>
                    <option value="01">Realme</option>
                    <option value="01">OnePlus</option>
                    <option value="01">Google</option>
                    <option value="01">TCL</option>
                    <option value="01">Alcatel</option>
                    <option value="01">Nokia</option>
                    <option value="01">Motorola</option>
                    <option value="01">CAT</option>
                    <option value="01">InnJoo</option>
                    <option value="01">Lenovo</option>
                    <option value="01">Microsoft</option>
                    <option value="01">Elephone</option>
                    <option value="01">HTC</option>
                    <option value="01">ZTE</option>
                    <option value="01">Vivo</option>
                    <option value="01">Asus</option>
                    <option value="01">Wiko</option>
                    <option value="01">Haier</option>
                  </select>
                </div>
              </div>


              <div className="col-sm-4">
                <div className="form-group">
                  <label className="text-primary" htmlFor="codigo-postal">Código Postal</label>
                  <input name="codigo-postal" className="form-control" type="text" id="codigo-postal" value=""/>
                </div>
              </div>


            </div>


            <div className="row">

              <div className="col-sm-4">
                <div className="form-group">
                  <label className="text-primary" htmlFor="nombre">Nombre</label>
                  <input name="nombre" className="form-control" type="text" id="nombre" value=""/>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label className="text-primary" htmlFor="celular">Teléfono celular</label>
                  <input name="celular" className="form-control" type="text" id="celular" value=""/>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group">
                  <label className="text-primary" htmlFor="email">Correo Electrónico</label>
                  <input name="email" className="form-control undefined" type="email" id="email" value="" />
                </div>
              </div>


            </div>

            <div className="row">

              <div className="col-lg-3 col-sm-12 col-12"></div>

              <div className="col-lg-6 col-sm-12 col-12">
                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">¡Ver mi cotización!</button>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12 col-12"></div>


            </div>





          </form>


          <div>
          </div>
        </div>

      </section>

      {/* Animacion */}
      <section className="bg-footer-hogar">
      <div>
        <img className="img-portada-hogar d-block" src={imgCel} alt="Seguro en mi Celular" />
      </div>
      </section>

      {/* Aseguradoras */}
      <ConfianEnNosotros />

      {/* Process */}
      <section className="container pt-5 pb-5 mt-2">
        <h2 className="h3 block-title text-center pt-2">¿Cómo funciona?</h2>
        <div className="row py-2">
          <div className="col-lg-4 col-sm-6">
            <div className="step step-with-icon">
              <div className="step-number">1</div>
              <div className="step-icon">
                <i className="fas fa-chalkboard-teacher step-icon-size" />
              </div>
              <h3 className="step-title">Cotizas</h3>
              <p className="step-text text-sm">
                Completás tus datos y los del auto y obtené los resultados en menos de 1 minuto.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="step step-with-icon">
              <div className="step-number">2</div>
              <div className="step-icon">
                <i className="far fa-object-ungroup step-icon-size" />
              </div>
              <h3 className="step-title">Comparas</h3>
              <p className="step-text text-sm">
                En un cuadro dinámico entre las mejores aseguradoras precios y compará coberturas.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="step step-with-icon">
              <div className="step-number">3</div>
              <div className="step-icon">
                <i className="far fa-handshake step-icon-size" />
              </div>
              <h3 className="step-title">Contratas</h3>
              <p className="step-text text-sm">
                Elegí online el seguró para vos y solicitá asistencia cuando quieras.
              </p>
            </div>
          </div>
        </div>
      </section>


      {/* Testimonials */}
      <section className="bg-secondary py-5">
        <div className="bg-center-top bg-no-repeat bg-cover">
          <div className="container">
            <h2 className="h4 block-title text-center mt-2">Lo que opinan nuestros clientes</h2>
            <OwlCarousel
              className="owl-carousel carousel-flush pt-3"
              config={{
                nav: false,
                dots: true,
                autoHeight: true,
                responsive: {
                  "0": { items: 1 },
                  "630": { items: 2 },
                  "991": { items: 3 },
                  "1200": { items: 3 },
                },
              }}
            >
              <div className="mb-30 pb-4">
                <div className="card blog-card">
                  <div className="card-body">
                    <p className="text-muted testimonial-p">
                      Muy atentos, mucha paciencia para explicarme distintas alternativas para mi
                      seguro.{" "}
                    </p>
                    <h6 className="post-title city">Buenos Aires</h6>
                    <div className="rating-stars">
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star" />
                    </div>
                  </div>
                  <div className="card-footer">
                    <a className="post-author" href="/">
                      <div className="post-author-avatar">
                        <img src={testimoniosH1} alt="Claudio Ortega" />
                      </div>
                      <div className="post-author-name">Claudio Ortega</div>
                    </a>
                    <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Junio, 2019
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-30 pb-4">
                <div className="card blog-card">
                  <div className="card-body">
                    <p className="text-muted testimonial-p">
                      Buenas noches, ya me llegó la póliza, muchas gracias por la atención de todos,
                      por cierto muy amables… Éxitos...
                    </p>
                    <h6 className="post-title city">Córdoba</h6>
                    <div className="rating-stars">
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                    </div>
                  </div>
                  <div className="card-footer">
                    <a className="post-author" href="/">
                      <div className="post-author-avatar">
                        <img src={testimoniosM1} alt="Silvia Saenz" />
                      </div>
                      <div className="post-author-name">Silvia Saenz</div>
                    </a>
                    <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Mayo, 2019
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-30 pb-4">
                <div className="card blog-card">
                  <div className="card-body">
                    <p className="text-muted testimonial-p">
                      Increible chicos, en 5 minutos ya tengo el auto aseguradooo!!! Gracias !!!
                    </p>
                    <h6 className="post-title city">Rosario</h6>
                    <div className="rating-stars">
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                    </div>
                  </div>
                  <div className="card-footer">
                    <a className="post-author" href="/">
                      <div className="post-author-avatar">
                        <img src={testimoniosM2} alt="Sara Cortez" />
                      </div>
                      <div className="post-author-name">Sara Cortez</div>
                    </a>
                    <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Abril, 2019
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-30 pb-4">
                <div className="card blog-card">
                  <div className="card-body">
                    <p className="text-muted testimonial-p">La atención fue de primera, gracias.</p>
                    <h6 className="post-title city">Buenos Aires</h6>
                    <div className="rating-stars">
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star active" />
                      <i className="fe-icon-star" />
                    </div>
                  </div>
                  <div className="card-footer">
                    <a className="post-author" href="/">
                      <div className="post-author-avatar">
                        <img src={testimoniosH2} alt="Pedro Romanazzi" />
                      </div>
                      <div className="post-author-name">Pedro Romanazzi</div>
                    </a>
                    <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Marzo, 2019
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <CotizacionModal
        show={this.state.showCotizacionForm}
        preCotizacionData={this.state.preCotizacionFormData}
        handleClose={() => {
          this.setState({ showCotizacionForm: false })
        }}
        onSubmit={this.onCotizacionModalSubmit}
      />
    </Layout>
  )
}

class CotizacionModal extends React.Component {
  cotizar(values, errorCallback, recaptchaResponse = "sin-captcha") {
    const { preCotizacionData, onSubmit } = this.props
    const telefonoPartes = values.celular.match(/^(0\d{2,4})\s?(15\d{6,8})$/)
    const fechaNacimiento = "01/01/" + (new Date().getFullYear() - parseInt(values.edad))
    const data = {
      nombre: values.nombre,
      tel_area: telefonoPartes[1],
      tel_numero: telefonoPartes[2],
      codigo_postal: values.cp,
      infoauto_codigo: preCotizacionData.version,
      anio: preCotizacionData.anio === "0" ? new Date().getFullYear() : preCotizacionData.anio,
      email: values.email,
      tiene_gnc: values.gnc,
      fecha_nacimiento: fechaNacimiento.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1"),
      sexo: values.sexo,
      estado_civil: values.estadoCivil,
      es_cero: values.anio === "0" ? 1 : 0,
      tiene_rastreador: 0,
      recaptcha_response: recaptchaResponse,
    }
    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/autos/cotizacion`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.hasOwnProperty("error_captcha")) {
          const recaptchaWidgetId = window.grecaptcha.render(this.recaptchaWidget, {
            sitekey: "6LfRKzIUAAAAAMT9kN8vexdJKYhvZBUcCdZDHfwu",
            size: "invisible",
            callback: recaptchaResponse => {
              this.cotizar(values, errorCallback, recaptchaResponse)
            },
            "error-callback": () => {
              errorCallback(
                "No se pudo continuar en este momento, intente nuevamente más tarde por favor."
              )
            },
          })
          window.grecaptcha.execute(recaptchaWidgetId)
        } else if (!response.hasOwnProperty("token")) {
          errorCallback("Ocurrió un error, intente nuevamente más tarde por favor.")
        } else {
          values.token = response.token
          onSubmit(values)
          // enviar el mail de cotización nueva
          const asunto = `CA: ${data.email} ${data.anio} ${preCotizacionData.marcaDescripcion} ${preCotizacionData.versionDescripcion}`
          const cuerpo = `
            <p>Has recibido una nueva oportunidad comercial desde tu sitio web.</p>
            <p>
              <a href="https://centrix.wokan.com.ar/acbytoken/${values.token}">
                Ver datos de la oportunidad en Wokan</a>.
            </p>
            <h2>Datos del interesado</h2>
            <dl>
              <dt>Nombre</dt>
              <dd>${data.nombre}</dd>
              <dt>Correo electrónico</dt>
              <dd>${data.email}</dd>
              <dt>Celular</dt>
              <dd>(${data.tel_area}) ${data.tel_numero}</dd>
              <dt>Vehiculo</dt>
              <dd>
                ${preCotizacionData.marcaDescripcion} -
                ${preCotizacionData.versionDescripcion} -
                ${data.anio}
              </dd>
            </dl>
          `
          const params = { asunto, cuerpo, responder: data.email }
          fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
              "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
              "Content-Type": "application/json",
            },
          })
        }
      })
      .catch(err => {
        errorCallback(
          "¿Está conectado a internet? Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
        )
      })
  }

  render() {
    const { show, handleClose, preCotizacionData } = this.props
    return (
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: 0 }}>
            <span className="text-primary">Último Paso!</span> <br/> Completá tus datos para finalizar la cotización
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0 0" }}>
          <div
            className="page-title d-flex"
            style={{
              minHeight: "150px",
              backgroundImage: `url(${wdsPattern})`,
              backgroundPosition: "80% 50%",
              marginBottom: 0,
            }}
          >
            <div className="container text-left align-self-center">
              <div className="row">
                <div className="px-4 col-lg-7">
                  <h2 className="page-title-heading">{preCotizacionData.marcaDescripcion}</h2>
                  <div className="page-title-subheading font-weight-bold">
                    {preCotizacionData.versionDescripcion} Año {preCotizacionData.anio === "0" ? "OKM" : preCotizacionData.anio}
                  </div>
                </div>
                <div className="px-4 col-lg-5">
                  <div className="page-title-subheading font-weight-bold">
                    Estas a un paso de obtener.
                  </div>
                  <h3 className="text-primary title-descuento">Un 30% de descuento en tú seguro!</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body" style={{ padding: "1rem 1.25rem" }}>
            <Formik
              initialValues={{
                nombre: "",
                cp: "",
                sexo: "",
                estadoCivil: "",
                celular: "",
                email: "",
                edad: "",
                gnc: "",
              }}
              validationSchema={() =>
                Yup.object().shape({
                  nombre: Yup.string()
                    .trim()
                    .required("Requerido"),
                  cp: Yup.number()
                    .integer("Ingrese sólo números")
                    .min(1000, "Inválido")
                    .max(9999, "Inválido")
                    .required("Requerido"),
                  sexo: Yup.string().required("Requerido"),
                  estadoCivil: Yup.string().required("Requerido"),
                  celular: Yup.string()
                    .trim()
                    .matches(/^0\d{2,4}\s?15\d{6,8}$/, "Ingrese el 0 y el 15")
                    .required("Requerido"),
                  email: Yup.string()
                    .email("Inválido")
                    .required("Requerido"),
                  edad: Yup.number()
                    .integer("Ingrese sólo números")
                    .min(18, "Edad no permitida")
                    .max(99, "Edad no permitida")
                    .required("Requerido"),
                  gnc: Yup.boolean().required("Requerido"),
                })
              }
              onSubmit={(values, { setSubmitting }) => {
                if (window.gtag) {
                  window.gtag("event", "datos_personales")
                }
                this.cotizar(values, error => {
                  alert(error)
                  setSubmitting(false)
                })
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="text-muted">Nombre y apellido</label>
                        <Field
                          name="nombre"
                          className={`form-control ${errors.nombre &&
                            touched.nombre &&
                            " is-invalid"}`}
                          type="text"
                        />
                        <ErrorMessage name="nombre" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label className="text-muted">Codigo postal</label>
                        <Field
                          name="cp"
                          className={`form-control ${errors.cp && touched.cp && " is-invalid"}`}
                          type="number"
                          placeholder="Ej: 1704"
                        />
                        <ErrorMessage name="cp" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label className="text-muted">Sexo</label>
                        <Field
                          name="sexo"
                          className={`form-control ${errors.sexo && touched.sexo && " is-invalid"}`}
                          component="select"
                        >
                          <option value="">Seleccionar...</option>
                          <option value="m">Masculino</option>
                          <option value="f">Femenino</option>
                        </Field>
                        <ErrorMessage name="sexo" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label className="text-muted">Edad</label>
                        <Field
                          name="edad"
                          className={`form-control ${errors.edad && touched.edad && " is-invalid"}`}
                          type="number"
                        />
                        <ErrorMessage name="edad" component="div" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="text-muted">Estado civil</label>
                        <Field
                          name="estadoCivil"
                          className={`form-control ${errors.estadoCivil &&
                            touched.estadoCivil &&
                            " is-invalid"}`}
                          component="select"
                        >
                          <option value="">Seleccionar...</option>
                          <option value="soltero">Soltero</option>
                          <option value="casado">Casado / Concubinato</option>
                          <option value="divorciado">Divorciado</option>
                          <option value="viudo">Viudo</option>
                        </Field>
                        <ErrorMessage
                          name="estadoCivil"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-muted">Teléfono celular</label>
                        <Field
                          name="celular"
                          className={`form-control ${errors.celular &&
                            touched.celular &&
                            " is-invalid"}`}
                          type="text"
                          placeholder="Ej: 011 1577778888"
                        />
                        <ErrorMessage name="celular" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label className="text-muted">Correo Electrónico</label>
                        <Field
                          name="email"
                          className={`form-control ${errors.email &&
                            touched.email &&
                            " is-invalid"}`}
                          type="email"
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label className="text-muted">Tiene GNC</label>
                        <Field
                          name="gnc"
                          className={`form-control ${errors.gnc && touched.gnc && " is-invalid"}`}
                          component="select"
                        >
                          <option value="">Seleccionar...</option>
                          <option value="0">No</option>
                          <option value="1">Si</option>
                        </Field>
                        <ErrorMessage name="gnc" component="div" className="invalid-feedback" />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary btn-block mb-3"
                  >
                    {isSubmitting ? "Un momento por favor..." : "¡Ver mi cotización!"}
                  </button>
                  <div
                    className="g-recaptcha"
                    ref={e => {
                      this.recaptchaWidget = e
                    }}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer className="m" style={{ background: "#343b43", textAlign: "center" }}>
          <div className="col-xl-12">
            <ul className="ul-log list-inline text-center">
              <li>
                <a href="http://www.jus.gob.ar/datos-personales.aspx/">
                  <img src={pdp} alt="PDP" />
                </a>
              </li>
              <li>
                <a href="http://www2.ssn.gob.ar/">
                  <img src={ssn} alt="SSN" />
                </a>
              </li>
            </ul>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}
